export function currencyFormat(value?: number | null): string | null {
  if (value) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(value / 100);
  } else {
    return null;
  }
}
